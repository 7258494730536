import { Container, Box, Paper } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
//import Header
import NAV from "../components/Nav";
import HEADER from "../components/Header.js";
//Import ContactUs
//Import OurPartners
import LOGISTICS_SERVICES from "../components/LogisticsServices";
import CONSTRUCTION_SERVICES from "../components/ConstructionServices";
import OURSOLUTIONS from "../components/OurSolutions";
import OURPARTNERS from "../components/OurPartners";
import GALLARY from "../components/Gallary"
import SOCAILMEDIA from "../components/SocialMedia.js";
import CONTACTUS from "../components/ContactUs.js";
import FOOTER from "../components/Footer";

const Home = (props) => {
  useEffect(() => {
    // component mount
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    return () => {
      // component unmount
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    return false; // force disable
  };

  return (
    <Fragment>
      <NAV />
      <Box>
        <Container maxWidth="xl">
          <Paper elevation={24}>
            <PageComponets />
          </Paper>
        </Container>
      </Box>
      <FOOTER />
    </Fragment>
  );
};

const PageComponets = () => {
  return (
    <>
      <HEADER />
      <OURSOLUTIONS />
      <CONSTRUCTION_SERVICES />
      <LOGISTICS_SERVICES />
      <OURPARTNERS />
      <GALLARY />
      <CONTACTUS />
      <SOCAILMEDIA />
    </>
  );
};

export default Home;
