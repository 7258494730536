import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { HeaderData } from "../data/HeaderData.js";
import "../styles/HeaderStyle.css";

const Header = (props) => {
  return (
    <Card className="rootHeader">
      <CardActionArea>
        {/* <img className="headerImg" src={HeaderData.headerImg} /> */}
        <CardMedia
          className={"media"}
          image={HeaderData.headerImg}
          title=""
        />
        <CardContent>
          <Typography
            className="headerText"
            gutterBottom
            variant="h5"
            component="h2"
          >
            {HeaderData.header}
          </Typography>
          <Typography
            className="headerText"
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {HeaderData.headerBody}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="cardButtons">
        <Button
          href="#ContactUs"
          className="contactUsButton"
          size="large"
          variant="outlined"
          color="secondary"
        >
          Contact Us
        </Button>
      </CardActions>
    </Card>
  );
};

export default Header;
