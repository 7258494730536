import { Grid, Typography, Card, CardActionArea } from "@material-ui/core";
import { OurPartnersData } from "../data/OurPartnersData.js";
import "../styles/OurPartnersStyle.css";

const OurPartners = (props) => {
  return (
    <div id="partners" className={"OurPartnersRoot"}>
      <Typography className="HeaderTitle" variant="h5" component="h2">
        Our Partners
      </Typography>
      <Grid className="PartnersGrid" container spacing={3}>
        {OurPartnersData.map((item, idx) => (
          <Grid item key={idx}>
            <Card
              className="PartnerCard"
              style={{ backgroundColor: item.backgroundColor }}
            >
              <CardActionArea href={item.Link}>
                <img
                  className="CardMedia"
                  src={item.pic}
                  alt={"partner-" + idx}
                />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OurPartners;
