import { FooterData } from "../data/FooterData";
import "../styles/FooterStyle.css";

export default function Footer() {
  return (
    <div className="Footer-bar">
      <div className="text-center ">{FooterData.text} </div>
      <a className="FSDLink" href={FooterData.fsdLink}>
        <div className="text-center ">{FooterData.fsdTag} </div>
      </a>
    </div>
  );
}
