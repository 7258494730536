import React from "react";

import { Card, CardMedia, Grid, Paper, Typography } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import { OurClientsData } from "../data/OurClientsData.js";

import "../styles/OurClientsStyle.css";
const OurClients = (props) => {
  var invertMode = false;
  var objectOrder = [0, 1];

  const handleMapInvert = () => {
    invertMode = invertMode ? false : true;
    objectOrder = invertMode ? [1, 0] : [0, 1];
  };

  return (
    <div id="ConstructionServices" className="OurClients">
      <Typography className="HeaderTitle" variant="h5" component="h2">
        Construction Services
      </Typography>
      <Hidden mdDown>
      {OurClientsData.map((item, idx) => (
        <Grid
          className="CenterAlignGridItems"
          container
          justify="center"
          spacing={6}
          key={idx}
        >
          {objectOrder.map((value, idx) => (
            <Grid item key={idx}>
              <Paper elevation={0} className={"Paper"}>
                {value < 1 ? (
                  <img
                    className="ConstructionMedia"
                    src={item.pic}
                    alt={"construction-service-" + idx}
                  />
                ) : (
                  // <img
                  //   className="Media"
                  //   src={item.pic}
                  //   alt={"construction-service-" + idx}
                  // />
                  <>
                    <h3 className="ConstructionType"> {item.ProjectTitle}</h3>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
          {handleMapInvert()}
        </Grid>
      ))}
      </Hidden>
      <Hidden mdUp>
      {OurClientsData.map((item, idx) => (
        <Grid
          className="CenterAlignGridItems"
          container
          justify="center"
          spacing={6}
          key={idx}
        >
          {objectOrder.map((value, idx) => (
            <Grid item key={idx}>
              <Paper elevation={0} className={"Paper"}>
                {value < 1 ? (
                  <img
                    className="ConstructionMedia"
                    src={item.pic}
                    alt={"construction-service-" + idx}
                  />
                ) : (
                  // <img
                  //   className="Media"
                  //   src={item.pic}
                  //   alt={"construction-service-" + idx}
                  // />
                  <>
                    <h3 className="ConstructionType"> {item.ProjectTitle}</h3>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      ))}
      </Hidden>
    </div>
  );
};

export default OurClients;
