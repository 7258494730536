import logistics from "../assets/con-log-min.jpg";
import network from "../assets/trucks-min.jpg";
import fleet from "../assets/fleet-min.jpg";
import shipping from "../assets/shipping-min.jpg";


const OurPortfolioData = [
    {
      pic: logistics,
      ProjectTitle: "Development Services:",
      exampleBio:
        "We ensure construction sites are equipped with the right resources to keep the project moving toward completion.",
    },
    {
      pic: network,
      ProjectTitle: "L-sync Network:",
      exampleBio:
        "We broker relationships within our strong network to help our clients.",
    },
    {
      pic: fleet,
      ProjectTitle: "Fleet Managment:",
      exampleBio:
        "With over 60 trucks available, we have the capacity to handle a broad range of cost-efficient and timely delivery services.",
    },
    {
      pic: shipping,
      ProjectTitle: "Shipping:",
      exampleBio:
        "We connect products to our clients’ customers and stakeholders.",
    },
    
  ];
  
  export { OurPortfolioData };
  