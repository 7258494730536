import React, { Component } from "react";
import {
  Button,
  Card,
  Container, 
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { ContactUsLogic } from "../logic/ContactUsLogic.js";
import { ContactUsData } from "../data/ContactUsData.js";
import "../styles/ContactUsStyle.css";

class ContactUs extends Component {
  /* Possible Message States:
   * unsent: [waiting for user input and send command]
   * sent:   [message was sent successfully]
   * loading: [form goes into a loading state]
   * failed: [attempted to send message but a fault occured]
   */
  constructor(props) {
    super(props);
    this.state = {
      // Message Data
      name: "",
      company: "",
      email: "",
      message: "",
      // Styling State
      nameValidation: { isInvalid: false, message: "" },
      companyValidation: { isInvalid: false, message: "" },
      emailValidation: { isInvalid: false, message: "" },
      messageValidation: { isInvalid: false, message: "" },
      // messageState
      messageState: "unsent",
    };
    this.CUL = new ContactUsLogic(this);
  }

  render() {
    return (
      <div id="ContactUs" className="FormStyle">
        <Typography className="" variant="h5" component="h2">
          {ContactUsData.title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Container>
              <ContactUsDescription />
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
            {this.state.messageState === "unsent" && (
              <ContactUsFormItems
                setMessageState={""}
                modal={this.state}
                CUL={this.CUL}
              />
            )}
            {this.state.messageState === "sent" && <ContactUsFormSuccess CUL={this.CUL} />}
            {this.state.messageState === "loading" && <ContactUsFormLoading />}
            {this.state.messageState === "failed" && (
              <ContactUsFormFailed CUL={this.CUL} />
            )}
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const ContactUsDescription = (props) => {
  return (
    <Card className="FormCard">
      <Typography className="FormSubTitleStyle" variant="h5" component="h2">
        {ContactUsData.bodyTitle}
      </Typography>
      <Typography className="" variant="body1">
        {ContactUsData.body}
      </Typography>
    </Card>
  );
};

const ContactUsFormSuccess = (props) => {
  return (
    <Paper elevation={0}>
      <Typography className="FormSubTitleStyle" variant="h5" component="h2">
        {ContactUsData.SuccessTitle}
      </Typography>
      <Typography className="FormSubTitleStyle" variant="body1">
        {ContactUsData.SuccessBody}
      </Typography>
      <div className="SubmitButtonContainer">
        <Button
          className="SubmitButton"
          variant="outlined"
          color="secondary"
          type="submit"
          onClick={props.CUL.handleClearedStateRetry}
        >
          {ContactUsData.ButtonRetryText}
        </Button>
      </div>
    </Paper>
  );
};

const ContactUsFormLoading = (props) => {
  return (
    <Paper className="FormLoading" elevation={0}>
      <CircularProgress color="secondary" />
    </Paper>
  );
};

const ContactUsFormFailed = (props) => {
  return (
    <Paper elevation={0}>
      <Typography className="FormSubTitleStyle" variant="h5" component="h2">
        {ContactUsData.FailedTitle}
      </Typography>
      <Typography className="FormSubTitleStyle" variant="body1">
        {ContactUsData.FailedBody}
      </Typography>
      <div className="SubmitButtonContainer">
        <Button
          className="SubmitButton"
          variant="outlined"
          color="secondary"
          type="submit"
          onClick={props.CUL.handleRetry}
        >
          {ContactUsData.ButtonRetryText}
        </Button>
      </div>
    </Paper>
  );
};

const ContactUsFormItems = (props) => {
  return (
    <form
      className="FormStyle"
      noValidate
      autoComplete="off"
      onSubmit={props.CUL.handleSubmit}
    >
      <div className="FormItemStyle">
        <Card className="FormCard">
          <div>
            <Typography className="FormLabelStyle" variant="subtitle1">
              {ContactUsData.Name}
            </Typography>

            <TextField
              error={props.modal.nameValidation.isInvalid}
              helperText={props.modal.nameValidation.message}
              required
              id="standard-required"
              label="Required"
              className="TextFieldStyle"
              value={props.modal.name}
              onChange={props.CUL.handleNameChange}
            />
          </div>

          <div>
            <Typography className="FormLabelStyle" variant="subtitle1">
              {ContactUsData.Company}
            </Typography>
            <TextField
              error={props.modal.companyValidation.isInvalid}
              helperText={props.modal.companyValidation.message}
              required
              id="standard-required"
              label="Required"
              className="TextFieldStyle"
              value={props.modal.company}
              onChange={props.CUL.handleCompanyChange}
            />
          </div>
          <div>
            <Typography className="FormLabelStyle" variant="subtitle1">
              {ContactUsData.Email}
            </Typography>
            <TextField
              error={props.modal.emailValidation.isInvalid}
              helperText={props.modal.emailValidation.message}
              required
              id="standard-required"
              label="Required"
              className="TextFieldStyle"
              value={props.modal.email}
              onChange={props.CUL.handleEmailChange}
            />
          </div>
          <div>
            <Typography className="FormLabelStyle" variant="subtitle1">
              {ContactUsData.Message}
            </Typography>
            <TextField
              error={props.modal.messageValidation.isInvalid}
              helperText={props.modal.messageValidation.message}
              required
              multiline
              rows={4}
              id="standard-required"
              label="Required"
              className="TextFieldStyle"
              value={props.modal.message}
              onChange={props.CUL.handleMessageChange}
            />
          </div>
          <div className="SubmitButtonContainer">
            <Button
              className="SubmitButton"
              variant="outlined"
              color="secondary"
              type="submit"
            >
              {ContactUsData.ButtonSubmitText}
            </Button>
          </div>
        </Card>
      </div>
    </form>
  );
};

export default ContactUs;
