import React, { Component, useState } from "react";
import { Grid, Paper, Typography, CardMedia, Card, CardActionArea, Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Skeleton from "@material-ui/lab/Skeleton";
import { GallaryData } from "../data/GallaryData.js";
import "../styles/Gallary.css";

const Gallary= (props) => {
  const [open, setOpen] = useState(false);
  const [pic, setPic] = useState(null);

  const handleClickOpen = (pic) => {
    setOpen(true);
    setPic(pic)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="partners" className={"GallaryRoot"}>
      <Typography className="HeaderTitle" variant="h5" component="h2">
        Projects Gallery
      </Typography>
      <Grid className="PartnersGrid" container spacing={3}>
        {GallaryData.map((item) => (
          <Grid item x>
            {/* <Paper
              className={"paper"}
              onClick={() => {
                alert("FSD: link not implemented");
              }}
            > */}
              <Card elevation={0} className="PartnerCard" style={{backgroundColor: "transparent"}}>
                <CardActionArea onClick={() => handleClickOpen(item.pic)}>
                  <img className="GallaryMedia" src={item.pic} /> 
                </CardActionArea>
              </Card>
          </Grid>
        ))}
      </Grid>
      <AlertDialog imageTarget={pic} open={open} handleClose={handleClose} />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = (props) => {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={false}
        maxWidth={"lg"}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Gallary"}</DialogTitle>
        <DialogContent>
        <img className="GallaryMediaDialog" src={props.imageTarget} />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Gallary;
