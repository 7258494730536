import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../assets/l-sync-logo.jpg";
import "../styles/NavStyle.css";
import { NavData } from "../data/NavData";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

//import NavigationLogic from "../logic/NavLogic";

export default function PrimarySearchAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <a className="MobileNavItem" href="#AboutUS" onClick={handleMenuClose}>
        <MenuItem className="MobileNav">
          <Typography>{NavData.AboutUs}</Typography>
        </MenuItem>
      </a>
      <a
        className="MobileNavItem"
        href="#OurSolutions"
        onClick={handleMenuClose}
      >
        <MenuItem className="MobileNav">
          <Typography>{NavData.Solutions}</Typography>
        </MenuItem>
      </a>
      <a className="MobileNavItem" href="#ConstructionServices" onClick={handleMenuClose}>
        <MenuItem className="MobileNav" /*onClick={handleProfileMenuOpen}*/>
          <Typography>{NavData.Clients}</Typography>
        </MenuItem>
      </a>
      <a className="MobileNavItem" href="#DevelopmentServices" onClick={handleMenuClose}>
        <MenuItem className="MobileNav">
          <Typography>{NavData.Portfolio}</Typography>
        </MenuItem>
      </a>
      <a className="MobileNavItem" href="#partners" onClick={handleMenuClose}>
        <MenuItem className="MobileNav">
          <Typography>{NavData.Partners}</Typography>
        </MenuItem>
      </a>
      <a className="MobileNavItem" href="#ContactUs" onClick={handleMenuClose}>
        <MenuItem className="MobileNav" /*onClick={handleProfileMenuOpen}*/>
          <Typography>{NavData.ContactUs}</Typography>
        </MenuItem>
      </a>
    </Menu>
  );
  return (
    <div className="Grow">
      <AppBar position="static" className="NavComp">
        <Toolbar>
          <Grid>
            <img alt="logo" src={Logo} width="110" height="35.56" />
          </Grid>

          <div className="Grow" />
          <Box display={{ xs: "none", md: "flex" }}>
            <IconButton
              href="#AboutUS"
              aria-label=""
              color="inherit"
            >
              <Typography> {NavData.AboutUs}</Typography>
            </IconButton>
            <IconButton
              href="#OurSolutions"
              aria-label=""
              color="inherit"
            >
              <Typography>{NavData.Solutions}</Typography>
            </IconButton>
            <IconButton
              href="#ConstructionServices"
              aria-label=""
              color="inherit"
            >
              <Typography>{NavData.Clients}</Typography>
            </IconButton>
            <IconButton
              href="#DevelopmentServices"
              aria-label=""
              color="inherit"
            >
              <Typography>{NavData.Portfolio}</Typography>
            </IconButton>
            <IconButton
              href="#partners"
              aria-label=""
              color="inherit"
            >
              <Typography>{NavData.Partners}</Typography>
            </IconButton>
            <IconButton
              href="#ContactUs"
              aria-label=""
              color="inherit"
            >
              <Typography>{NavData.ContactUs}</Typography>
            </IconButton>
          </Box>
          <Box display={{ xs: "flex", md: "none" }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
