
const ContactUsData = {
    title:"Contact Us",
    // Form Description Section
    bodyTitle: "Welcome to L-Sync Solutions",
    body:"L-Sync partners with customers in a thoughtful approach to delivering their project on time and within budget. L-Sync leverages its expertise and industry knowledge to mitigate challenges, thereby turning them into stepping stones for success.", 
    
    // Form Section
    Name: "Your Name:", 
    Company:"Company:",
    Email:"Email:",
    Message:"Message:", 

    // Submit Button
    ButtonSubmitText:"Submit",

    //Retry Button
    ButtonRetryText:"Retry",

    // Success View 
    SuccessTitle: "Message Sent",
    SuccessBody: "Your message has been delivered. We'll get back with you shortly.",

    // Failed View
    FailedTitle: "An error has occured",
    FailedBody: "We've failed to deliver your message due to a technical issue. Please try again."
}; 

export { ContactUsData }; 