import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { OurPortfolioData } from "../data/OurPortfolioData.js";
import Hidden from '@material-ui/core/Hidden';


import "../styles/OurPortfolioStyle.css";
const LogisticsServices = (props) => {
  var invertMode = false;
  var objectOrder = [0, 1];

  const handleMapInvert = () => {
    invertMode = invertMode ? false : true;
    objectOrder = invertMode ? [1, 0] : [0, 1];
  };

  return (
    <div id="DevelopmentServices" className="OurPortfolio">
      <Typography className="PortfolioHeaderTitle" variant="h5" component="h2">
        Development Services
      </Typography>
      <Hidden mdDown>
      {OurPortfolioData.map((item, idx) => (
        <Grid
          className="CenterAlignGridItems"
          container
          justify="center"
          spacing={6}
          key={idx}
        >
          {objectOrder.map((value, idx) => (
            <Grid item key={idx}>
              <Paper elevation={0} className={"PortfolioPaper"}>
                {value < 1 ? (
                  <img
                    className="LogicMedia"
                    src={item.pic}
                    alt={"logistic-service-" + idx}
                  />
                ) : (
                  <>
                    <h3> {item.ProjectTitle} </h3>
                    <Typography> {item.exampleBio} </Typography>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
          {handleMapInvert()}
        </Grid>
      ))}
      </Hidden>
      <Hidden mdUp>
      {OurPortfolioData.map((item, idx) => (
        <Grid
          className="CenterAlignGridItems"
          container
          justify="center"
          spacing={6}
          key={idx}
        >
          {objectOrder.map((value, idx) => (
            <Grid item key={idx}>
              <Paper elevation={0} className={"PortfolioPaper"}>
                {value < 1 ? (
                  <img
                    className="LogicMedia"
                    src={item.pic}
                    alt={"logistic-service-" + idx}
                  />
                ) : (
                  <>
                    <h3> {item.ProjectTitle} </h3>
                    <Typography> {item.exampleBio} </Typography>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      ))}
      </Hidden>
    </div>
  );
};

export default LogisticsServices;
