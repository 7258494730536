import image1 from "../assets/gallery/1.jpg"
import image2 from "../assets/gallery/2.jpg"
import image3 from "../assets/gallery/3.jpg"
import image4 from "../assets/gallery/4.jpg"
import image5 from "../assets/gallery/5.jpg"
import image6 from "../assets/gallery/6.jpg"
import image7 from "../assets/gallery/7.jpg"
import image8 from "../assets/gallery/8.jpg"
import image9 from "../assets/gallery/9.jpg"
import image10 from "../assets/gallery/10.jpg"
import image11 from "../assets/gallery/11.jpg"
import image12 from "../assets/gallery/12.jpg"



const GallaryData = [
  {
    pic: image1,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image2,
    Label: "Logo",
    backgroundColor: "#0b4689"
  },
  {
    pic: image3,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image4,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image5,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image6,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image7,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image8,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image9,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image10,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image11,
    Label: "Logo",
    backgroundColor: "#fff"
  },
  {
    pic: image12,
    Label: "Logo",
    backgroundColor: "#fff"
  },
];

export { GallaryData };
