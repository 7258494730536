import construction from "../assets/cover-min.jpg";
import logistics from "../assets/log-min.jpg";

const OurSolutionsData = [
    {
      header: "Construction Services",
      pic: construction,
      exampleBio:
      "L-Sync Solution has consistently and continuously provided the highest quality construction contracting service to various industries since 2008. Our management team collectively represents over 30 years of Construction experience specializing in Aerospace, Healthcare, Military Defense, Manufacturing, and Telecommunications, Entertainment and Fortune 500 Clients. Our service offering is built upon a combination of diligence, flexibility, and process, all of which provides a thorough understanding of our clients’ needs."
    },
    {
      header: "Development Services:      ",
      pic: logistics,
      exampleBio:
        "L-Sync Solutions manages all aspects of the development process through construction land acquisition, financing. L-Sync Solutions comprehensive background is inclusive of developer, co-developer, development advisor, construction manager, property manager and contractor. In the area of urban development, L-Sync Solutions has a hands-on approach to community development and has positioned the firm as one of the most nationally sought-after real estate developers by housing authorities, political and religious leaders, retailers and corporations, all of which are looking for ways to maximize capital and programmatic return on investment. Clients Such as: Ft. Worth Housing Authority's , MSHDA(Michigan State Housing Development Authority), L-Sync Solutions serves to create a sense of shared ownership that ensures all projects are handled thoroughly from concept through project sunset.",
    },
  ];
  
  export { OurSolutionsData };