import attLogo from "../assets/att-2.png";
import cityOfDallasLogo from "../assets/COD.jpg";
import dfwairportLogo from "../assets/DFW_airport_logo.svg"
import dfwLogo from "../assets/Grand-Hyatt.jpg";
import methodistLogo from "../assets/Methodist-hs.jpg";
import morehouseLogo from "../assets/Morehouse_college_seal.svg.png";
import tdilogo from "../assets/TDILogo.webp";
import texasRangersLogo from "../assets/Texas-rangers.jpg";
import tmobileLogo from "../assets/tmobile.jpg";

const OurPartnersData = [
  {
    pic: attLogo,
    Link: "https://www.att.com/",
    backgroundColor: "#fff"
  },
  {
    pic: cityOfDallasLogo,
    Link: "https://dallascityhall.com/",
    backgroundColor: "#0b4689"
  },
  {
    pic: dfwairportLogo,
    Link: "https://www.dfwairport.com/",
    backgroundColor: "#fff"
  },
  {
    pic: dfwLogo,
    Link: "https://www.hyatt.com/en-US/hotel/texas/grand-hyatt-dfw/dfwgh",
    backgroundColor: "#fff"
  },
  {
    pic: methodistLogo,
    Link: "https://www.methodisthealthsystem.org/",
    backgroundColor: "#fff"
  },
  {
    pic: morehouseLogo,
    Link: "https://www.morehouse.edu/",
    backgroundColor: "#fff"
  },
  {
    pic: tdilogo,
    Link: "https://www.tdindustries.com/",
    backgroundColor: "#fff"
  },
  {
    pic: texasRangersLogo,
    Link: "https://www.mlb.com/rangers",
    backgroundColor: "#fff"
  },
  {
    pic: tmobileLogo,
    Link: "https://www.t-mobile.com/",
    backgroundColor: "#fff"
  },
];

export { OurPartnersData };
