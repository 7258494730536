import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
} from "@material-ui/core";
import { SocialMediaData } from "../data/SocialMediaData";
import "../styles/SocialMediaStyles.css";

export default function SocialMedia() {
  return (
      <Grid container>
        <Grid item xs></Grid>
        <Card className="SocialContainer">
          <CardContent>
            <Typography color="textSecondary" gutterBottom />
            {SocialMediaData.title}
          </CardContent>
          <CardActions>
            <Grid className="SocialGrid" item xs>
              {SocialMediaData.socials.map((item, idx) => {
                return <SocialMediaButton key={idx} {...item} />;
              })}
            </Grid>
          </CardActions>
        </Card>
      </Grid>
  );
}

function SocialMediaButton(props) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={props.link}
      className="Social-media-icon d-inline-block mx-1"
    >
      <img className="Img" src={props.icon.img} alt={props.icon.alt} />
    </a>
  );
}
