import constructionManagment from "../assets/planning.jpg";
import constructionClean from "../assets/site-clean.jpg";
import facilities from "../assets/fac-min.jpg";
import jantorial from "../assets/jan-min.jpg";
import concrete from "../assets/con-min.jpg";
import cost from "../assets/cost-min.jpg";
import done from "../assets/done-min.jpg";
import design from "../assets/design-min.jpg";


const OurClientsData = [
  {
    pic: constructionManagment,
    ProjectTitle: "Construction Management: ", 
    exampleBio:
      "",
  },
  {
    pic: constructionClean,
    ProjectTitle: "Construction Site Clean-up", 
    exampleBio:
      "",
  },
  {
    pic: facilities,
    ProjectTitle: "Facilities Management",
    exampleBio: 
      "",
  },
  {
    pic: jantorial,
    ProjectTitle: "Commercial Janitorial Services", 
    exampleBio: 
      "",
  },
  {
    pic: concrete,
    ProjectTitle: "Concrete Services",
    exampleBio:
      "",
  },
  {
    pic: cost,
    ProjectTitle: "Cost Estimation",
    exampleBio:
      "",
  },
  {
    pic: done,
    ProjectTitle: "Finish Work",
    exampleBio:
      "",
  },
  {
    pic: design,
    ProjectTitle: "Design Build",
    exampleBio:
      "",
  },
];

export { OurClientsData };
