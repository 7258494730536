import fb from "../assets/facebook.svg";
import li from "../assets/linkedin.svg";

export const SocialMediaData = {
  title: "Follow us",
  socials: [
    {
      link: "https://www.facebook.com/L-Sync-Solutions-422116987946229/",
      icon: { img: fb, alt: "L-SYNC-FB-Icon" },
    },
    {
      link: "https://www.linkedin.com/in/joenjames/",
      icon: { img: li, alt: "L-SYNC-LI-Icon" },
    },
  ],
};
