import axios from "axios";
export class ContactUsLogic {
  constructor(modelView) {
    this.model = modelView;
    // RFC 2822 |email regex|
    this.emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  }
  /* ======================================
   * FORM VALIDATION
   * ======================================*/

  isFormValid = () => {
    let validationState = true;
    if (this.model.state.name === "") {
      this.model.setState({
        nameValidation: { isInvalid: true, message: "Field Incomplete" },
      });
      validationState = false;
    }
    if (this.model.state.company === "") {
      this.model.setState({
        companyValidation: { isInvalid: true, message: "Field Incomplete" },
      });
      validationState = false;
    }
    // Check email format against RFC 2822 |email regex|
    if (!this.model.state.email.match(this.emailRegEx)) {
      this.model.setState({
        emailValidation: { isInvalid: true, message: "Invalid Email" },
      });
      validationState = false;
    }
    if (this.model.state.message === "") {
      this.model.setState({
        messageValidation: { isInvalid: true, message: "Field Incomplete" },
      });
      validationState = false;
    }

    return validationState;
  };
  /* ======================================
   * MESSAGE API
   * ======================================*/
  sendMail() {
    axios({
      method: "post",
      url:
        "https://us-central1-full-stack-web.cloudfunctions.net/contactFormMessaging",
      data: {
        project: "lSync",
        username: this.model.state.name,
        email: this.model.state.email,
        company: this.model.state.company,
        message: this.model.state.message,
      },
      headers: {},
    })
      .then(() => {
        this.model.setState({ messageState: "sent" });
      })
      .catch(() => {
        this.model.setState({ messageState: "failed" });
      })
      .finally(() => {});
  }
  /* ======================================
   * Handlers
   * ======================================*/
  handleNameChange = (e) => {
    // Re-validate while typing
    this.model.setState({ nameValidation: { isInvalid: false, message: "" } });
    this.model.state.name = e.target.value;
  };

  handleCompanyChange = (e) => {
    // Re-validate while typing
    this.model.setState({
      companyValidation: { isInvalid: false, message: "" },
    });
    this.model.state.company = e.target.value;
  };

  handleEmailChange = (e) => {
    // Re-validate while typing
    this.model.setState({ emailValidation: { isInvalid: false, message: "" } });
    this.model.state.email = e.target.value;
  };

  handleMessageChange = (e) => {
    // Re-validate while typing
    this.model.setState({
      messageValidation: { isInvalid: false, message: "" },
    });
    this.model.state.message = e.target.value;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // Form Validation
    // API CALL
    if (this.isFormValid()) {
      this.model.setState({ messageState: "loading" });
      this.sendMail();
    }
  };

  handleRetry = (e) => {
    if (this.isFormValid()) {
      this.model.setState({ messageState: "unsent" });
    }
  };

  handleClearedStateRetry = (e) => {
    this.model.setState({ name: "" });
    this.model.setState({ company: "" });
    this.model.setState({ email: "" });
    this.model.setState({ message: "" });

    if (this.isFormValid()) {
      this.model.setState({ messageState: "unsent" });
    }
  };
}
