const NavData = {
    AboutUs: "About Us",
    Solutions: "Our Solutions",
    Clients: "Construction Services" ,
    Portfolio: "Development Services",
    Partners: "Our Partners",
    ContactUs: "Contact" ,

};

export { NavData};
