import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { OurSolutionsData } from "../data/OurSolutionsData.js";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";

import "../styles/OurSolutionsStyle.css";

const OurSolutions = (props) => {
  return (
    <div id="OurSolutions" className="OurSolutions">
      <Typography
        className="OurSolutionsHeaderTitle"
        variant="h5"
        component="h2"
      >
        Our Solutions
      </Typography>
      <Box display={{ xs: "none", lg: "block", xl: "block" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Card className="Leftcard">
              <CardContent>
                <h3>{OurSolutionsData[0].header}</h3>
              </CardContent>
              <CardContent>
                <CardMedia
                  className={"Media"}
                  image={OurSolutionsData[0].pic}
                  title="Contemplative Reptile"
                />
              </CardContent>
              <CardContent>
                <Typography variant="body1">
                  {OurSolutionsData[0].exampleBio}
                </Typography>
                {/* Spacing to meet character limit of ajacent Card: 370 blank characters added to meet 894 */}
                <span>
                  {" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;{" "}
                </span>
              </CardContent>
              <Button
                href="#ConstructionServices"
                className="SerivceButtons"
                size="large"
                variant="outlined"
                color="secondary"
              >
                {" "}
                Construction Services{" "}
              </Button>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card className="Centercard">
              <CardContent>
                <h3>{OurSolutionsData[1].header}</h3>
              </CardContent>
              <CardContent>
                <CardMedia
                  className={"Media"}
                  image={OurSolutionsData[1].pic}
                  title="Contemplative Reptile"
                />
              </CardContent>
              <CardContent>
                <Typography variant="body1">
                  {OurSolutionsData[1].exampleBio}
                </Typography>
              </CardContent>
              <Button
                href="#DevelopmentServices"
                className="SerivceButtons"
                size="large"
                variant="outlined"
                color="secondary"
              >
                {" "}
                Development Services{" "}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box display={{ xs: "block", lg: "none", xl: "none" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className="Leftcard">
              <CardContent>
                <h3>{OurSolutionsData[0].header}</h3>
              </CardContent>
              <CardContent>
                <CardMedia
                  className={"Media"}
                  image={OurSolutionsData[0].pic}
                  title="Contemplative Reptile"
                />
              </CardContent>
              <CardContent>
                <Typography variant="body1">
                  {OurSolutionsData[0].exampleBio}
                </Typography>
              </CardContent>
              <Button
                href="#ConstructionServices"
                className="SerivceButtons"
                size="large"
                variant="outlined"
                color="secondary"
              >
                {" "}
                Construction Services{" "}
              </Button>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="Centercard">
              <CardContent>
                <h3>{OurSolutionsData[1].header}</h3>
              </CardContent>
              <CardContent>
                <CardMedia
                  className={"Media"}
                  image={OurSolutionsData[1].pic}
                  title="Contemplative Reptile"
                />
              </CardContent>
              <CardContent>
                <Typography variant="body1">
                  {OurSolutionsData[1].exampleBio}
                </Typography>
              </CardContent>
              <Button
                href="#DevelopmentServices"
                className="SerivceButtons"
                size="large"
                variant="outlined"
                color="secondary"
              >
                {" "}
                Development Services{" "}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OurSolutions;
