import headerImg from "../assets/headerG.jpg";


const HeaderData = {
    header: "L-Sync Solutions",
    headerBody:   "Our solutions extend beyond the project’s end-product. We endeavor to handle our customers and projects with the upmost care from project inception to completion",
    headerImg: headerImg
  };
  
  export { HeaderData };

